import {
  BaseTextField,
  Icon_default,
  __objRest,
  __spreadProps,
  __spreadValues,
  styled
} from "./chunk-GAQ63DB5.mjs";

// src/LexicalEditor/index.tsx
import {
  BeautifulMentionNode as LexicalMentionNode,
  PlaceholderNode,
  PlaceholderPlugin
} from "lexical-beautiful-mentions";

// src/LexicalEditor/LexicalEditor.tsx
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { $getRoot } from "lexical";

// src/LexicalEditor/plugins/LexicalOnChange.tsx
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
function OnChangePlugin({
  onChange
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState, editor);
    });
  }, [editor, onChange]);
  return null;
}

// src/LexicalEditor/LexicalEditor.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var LexicalEditorContainer = styled("div", {
  position: "relative"
});
var StyledContentEditable = styled(ContentEditable, BaseTextField, {
  padding: "$3 $2",
  borderRadius: "$rounded",
  "&:read-only": {
    backgroundColor: "$input_background_enabled",
    cursor: "not-allowed"
  }
});
var StyledPlaceholder = styled("div", {
  color: "$label_secondary_text_disabled",
  overflow: "hidden",
  position: "absolute",
  textOverflow: "ellipsis",
  top: "$3",
  left: "$3",
  fontWeight: "$regular",
  userSelect: "none",
  display: "inline-block",
  pointerEvents: "none",
  fontSize: "$2"
});
var beautifulMentionsTheme = {
  // Use the trigger name as the key
  $: "mentions-chip"
};
function LexicalEditor({
  children: plugins,
  placeholder,
  initialConfig = {},
  editorCss = {},
  onEditorChange
}) {
  const defaultConfig = __spreadValues({
    namespace: "LexicalEditor",
    theme: {
      beautifulMentions: beautifulMentionsTheme
    },
    onError: (error) => {
      console.error("Lexical Error:", error);
    }
  }, initialConfig);
  function onChange(editorState, editor) {
    const stringifiedEditorState = JSON.stringify(editorState.toJSON());
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
    const editorStateTextString = parsedEditorState.read(
      () => $getRoot().getTextContent()
    );
    onEditorChange(editorStateTextString);
  }
  return /* @__PURE__ */ jsxs(LexicalComposer, { initialConfig: defaultConfig, children: [
    /* @__PURE__ */ jsx(LexicalEditorContainer, { children: /* @__PURE__ */ jsx(
      RichTextPlugin,
      {
        contentEditable: /* @__PURE__ */ jsx(
          StyledContentEditable,
          {
            css: __spreadValues({}, editorCss),
            "aria-placeholder": placeholder,
            placeholder: /* @__PURE__ */ jsx(StyledPlaceholder, { children: placeholder })
          }
        ),
        placeholder: /* @__PURE__ */ jsx(StyledPlaceholder, { children: placeholder }),
        ErrorBoundary: LexicalErrorBoundary
      }
    ) }),
    plugins,
    /* @__PURE__ */ jsx(HistoryPlugin, {}),
    /* @__PURE__ */ jsx(AutoFocusPlugin, {}),
    /* @__PURE__ */ jsx(OnChangePlugin, { onChange })
  ] });
}

// src/LexicalEditor/plugins/LexicalDisabled.tsx
import { useLexicalComposerContext as useLexicalComposerContext2 } from "@lexical/react/LexicalComposerContext";
import { useEffect as useEffect2 } from "react";
function LexicalDisabledPlugin({
  isDisabled
}) {
  const [editor] = useLexicalComposerContext2();
  useEffect2(() => {
    editor.setEditable(!isDisabled);
  }, [editor, isDisabled]);
  return null;
}

// src/LexicalEditor/plugins/LexicalMentions.tsx
import { Popover } from "@radix-ui/react-popover";
import {
  BeautifulMentionsPlugin
} from "lexical-beautiful-mentions";
import { forwardRef } from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var LexicalMenuItem = styled("li", {
  all: "unset",
  padding: "$2 $4",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  cursor: "pointer",
  backgroundColor: "#fff",
  "&[aria-selected=true]": {
    backgroundColor: "rgba(80, 94, 113, 0.16)"
  },
  fontSize: "$1"
});
var LexicalMenuContent = styled("ul", {
  overflow: "auto",
  width: "min-content",
  padding: "$1 0px",
  background: "$neutral0",
  filter: "drop-shadow($elevation2)",
  borderRadius: "$rounded"
});
function CustomMenu(_a) {
  var _b = _a, {
    loading,
    menuCss
  } = _b, props = __objRest(_b, [
    "loading",
    "menuCss"
  ]);
  return /* @__PURE__ */ jsx2(Popover, { children: /* @__PURE__ */ jsx2(LexicalMenuContent, __spreadProps(__spreadValues({}, props), { css: menuCss })) });
}
var CustomMenuItem = forwardRef((_a, ref) => {
  var _b = _a, { selected, item } = _b, props = __objRest(_b, ["selected", "item"]);
  return /* @__PURE__ */ jsx2(LexicalMenuItem, __spreadProps(__spreadValues({}, props), { ref }));
});
function LexicalMentionsPlugin({
  mentionItems,
  menuCss,
  mentionItemLimit = 30
}) {
  return /* @__PURE__ */ jsx2(
    BeautifulMentionsPlugin,
    {
      items: mentionItems,
      menuComponent: (props) => /* @__PURE__ */ jsx2(CustomMenu, __spreadProps(__spreadValues({}, props), { menuCss })),
      menuItemComponent: CustomMenuItem,
      menuItemLimit: mentionItemLimit
    }
  );
}

// src/LexicalEditor/plugins/LexicalInitialState/LexicalInitialState.types.tsx
var DefaultDelimiters = {
  type: 1 /* Default */,
  delimiters: [" "],
  test: (s) => [" "].includes(s)
};

// src/LexicalEditor/plugins/LexicalMentionsTriggers.ts
var LexicalMentionsTriggers = {
  DOLLAR: "\\$",
  AMPERSAND: "&",
  EXCLAMATION: "!",
  AT: "@",
  HASH: "#",
  ASTERISK: "*"
};
var LexicalMentionDelimiters = {
  DOLLAR: "$",
  AMPERSAND: "&",
  EXCLAMATION: "!",
  AT: "@",
  HASH: "#",
  ASTERISK: "*"
};
function buildMentionDelimiter(delimiters, items) {
  return {
    delimiter: {
      type: 0 /* Mention */,
      delimiters,
      test: (s) => {
        if (!delimiters.includes(s[0])) {
          return false;
        }
        return items.includes(s.substring(1, s.length));
      }
    }
  };
}

// src/LexicalEditor/plugins/LexicalSingleLine.tsx
import { useLexicalComposerContext as useLexicalComposerContext3 } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { LineBreakNode, RootNode } from "lexical";
import { useEffect as useEffect3 } from "react";
function LexicalSingleLine() {
  const [editor] = useLexicalComposerContext3();
  useEffect3(
    () => mergeRegister(
      editor.registerNodeTransform(RootNode, (rootNode) => {
        var _a;
        if (rootNode.getChildrenSize() <= 1)
          return;
        (_a = rootNode.getLastChild()) == null ? void 0 : _a.remove();
      }),
      editor.registerNodeTransform(LineBreakNode, (node) => {
        node.remove();
      })
    ),
    [editor]
  );
  return null;
}

// src/LexicalEditor/plugins/LexicalValidation.tsx
import { useLexicalComposerContext as useLexicalComposerContext4 } from "@lexical/react/LexicalComposerContext";
import { useEffect as useEffect4 } from "react";
import { Fragment, jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var LexicalValidationPlugin = ({
  isValid,
  helpMessage
}) => {
  const [editor] = useLexicalComposerContext4();
  useEffect4(() => {
    const editorElement = editor.getRootElement();
    if (editorElement) {
      if (!isValid) {
        editorElement.classList.add("invalid-editor");
      } else {
        editorElement.classList.remove("invalid-editor");
      }
    }
  }, [isValid, editor]);
  return !isValid ? /* @__PURE__ */ jsxs2("div", { className: "help-message", children: [
    /* @__PURE__ */ jsx3(Icon_default, { name: "triangle-exclamation" }),
    helpMessage
  ] }) : /* @__PURE__ */ jsx3(Fragment, {});
};

// src/LexicalEditor/index.tsx
import { $createParagraphNode as $createParagraphNode2, $createTextNode as $createTextNode2, $getRoot as $getRoot3 } from "lexical";

// src/LexicalEditor/plugins/LexicalInitialState/LexicalInitialState.tsx
import { useLexicalComposerContext as useLexicalComposerContext5 } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot as $getRoot2 } from "lexical";
import { useEffect as useEffect5 } from "react";

// src/LexicalEditor/plugins/LexicalInitialState/utils/getCreateNode.tsx
import { $createTextNode } from "lexical";
import { $createBeautifulMentionNode } from "lexical-beautiful-mentions";
function getCreateNode(type) {
  switch (type) {
    case 0 /* Mention */:
      return (text) => $createBeautifulMentionNode("", text);
    case 1 /* Default */:
    default:
      return $createTextNode;
  }
}

// src/LexicalEditor/plugins/LexicalInitialState/utils/getTypeFromNode.tsx
function getTypeFromNode(value, delimiters) {
  for (const nodeDelimiter of delimiters) {
    if (nodeDelimiter.test(value)) {
      return nodeDelimiter.type;
    }
  }
  return 1 /* Default */;
}

// src/LexicalEditor/plugins/LexicalInitialState/utils/isNewNode.tsx
function isNewNode(char, delimiters) {
  for (const nodeDelimiter of delimiters) {
    if (nodeDelimiter.delimiters.includes(char)) {
      return true;
    }
  }
  return false;
}

// src/LexicalEditor/plugins/LexicalInitialState/utils/parseNodes.tsx
function parseNodes(value, delimiters) {
  var _a, _b, _c;
  const chars = value.split("");
  const nodes = [];
  let index = 0;
  while (chars.length) {
    const char = (_a = chars.shift()) != null ? _a : "";
    if (isNewNode(char, delimiters) && nodes[index] != null) {
      index++;
    }
    nodes[index] = {
      type: void 0,
      value: ((_c = (_b = nodes[index]) == null ? void 0 : _b.value) != null ? _c : "") + char
    };
  }
  return nodes.map(
    (node) => {
      var _a2;
      return __spreadProps(__spreadValues({}, node), {
        type: getTypeFromNode((_a2 = node.value) != null ? _a2 : "", delimiters)
      });
    }
  );
}

// src/LexicalEditor/plugins/LexicalInitialState/LexicalInitialState.tsx
function LexicalInitialStatePlugin({
  value,
  mentions
}) {
  const [editor] = useLexicalComposerContext5();
  const delimiters = [DefaultDelimiters];
  if (mentions != null) {
    delimiters.unshift(mentions.delimiter);
  }
  useEffect5(() => {
    editor.update(() => {
      var _a;
      const root = $getRoot2();
      root.clear();
      const paragraphNode = $createParagraphNode();
      const nodes = parseNodes(value, delimiters);
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const createNode = getCreateNode(node.type);
        if (createNode) {
          paragraphNode.append(createNode(node.value));
        }
      }
      (_a = paragraphNode.getLastChild()) == null ? void 0 : _a.selectEnd();
      root.append(paragraphNode);
    });
  }, [editor, value.split]);
  return null;
}

// src/LexicalEditor/index.tsx
var LexicalEditor_default = {
  LexicalEditor,
  $getRoot: $getRoot3,
  $createTextNode: $createTextNode2,
  $createParagraphNode: $createParagraphNode2,
  LexicalMentionsTriggers,
  LexicalMentionsPlugin,
  LexicalMentionDelimiters,
  buildMentionDelimiter,
  LexicalDisabledPlugin,
  LexicalMentionNode,
  LexicalInitialStatePlugin,
  LexicalValidationPlugin,
  PlaceholderPlugin,
  PlaceholderNode,
  LexicalSingleLine
};
export {
  LexicalEditor_default as default
};
